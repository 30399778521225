// App main
const main = async() => {
    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');
    
    // load the vue waypoint
    const { default: VueWaypoint } = await import(/* webpackChunkName: "vuewaypoint" */ 'vue-waypoint');
    Vue.use(VueWaypoint)

    // Create our vue instance
    const vm = new Vue({
        el: "#content-container",
        components: {
            'modal': () => import(/* webpackChunkName: "modal" */ '@/vue/Modal.vue'),
        },
        data: function() {
            return {
                currentCity: '',
                currentStrain: '',
                currentAccordion: false,
                modalLink : '',
                intersectionOptions: {
                    threshold: [0.25]
                }
            };
        },

        methods: {
            onWaypoint({ el, going, direction }) {
                if( this.$waypointMap.GOING_IN === going ) {
                    el.classList.add('active')
                }
            },

            loadModal(event) {
                var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
                if( event.target.href ) {
                    this.modalLink = event.target.href
                }
                else if( event.target.closest('a') ) {
                    if( isIE11 ) {
                        window.location.href = event.target.closest('a').href
                    } else {
                        this.modalLink = event.target.closest('a').href
                    }
                }
            },

            onModalClose() {
                this.modalLink = ''
            },

            testGoogleMap() {
                let map = googleMaps.getMap('dispensaryMap')

                console.log( map._map.center.lat() )
                console.log( map._map.center.lng() )
                console.log( map._map.zoom )
            },

            openAccordion(rowID, slug) {
                if( this.currentAccordion == rowID ) {
                    document.querySelector('#ar'+rowID).style.height=0
                    this.currentAccordion = false
                    history.replaceState( null, null, '#' )
                } else {

                    if( this.currentAccordion ) {
                        document.querySelector('#ar'+this.currentAccordion).style.height=0
                    }

                    this.currentAccordion = rowID
                    document.querySelector('#ar'+rowID).style.height = document.querySelector('#ar'+rowID).scrollHeight + 'px';
                    history.replaceState( null, null, '#ai'+rowID + '-' + slug )
                }
            },

            scrollToID(id) {
                const yOffset = -100;
                const element = document.getElementById(id);
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                window.scrollTo({top: y, behavior: 'smooth'});
            },

            changeCity() {
                const re1 = /\/dispensaries/i
                const re2 = /\/deals/i
                if( window.location.pathname.match(re1) ) {
                    window.location = '/' + this.currentCity + '/dispensaries'
                } else if( window.location.pathname.match(re2) ) {
                    window.location = '/' + this.currentCity + '/deals'
                } else {
                    window.location = '/' + this.currentCity
                }
            },

            changeStrain() {
                const params = new URLSearchParams(window.location.search)

                if( params.has('q') ) {
                    window.location = "/strains/" + this.currentStrain + '?q=' + params.get('q')
                } else {
                    window.location = "/strains/" + this.currentStrain
                }
                
            }
        },

        mounted() {
            let match = window.location.hash.match(/^#ai(\d+).*?/gi)
            if( match ) {
                let rowID = match[0].replace( '#ai', '' )
                let slug  = window.location.hash.replace( match[0] + '-', '' )
                this.openAccordion( rowID, slug )
                this.scrollToID( 'ai'+rowID )
            } else {
                let match2 = window.location.hash.match(/^#([\w\-]+)/gi)
                if( match2 ) {
                    this.scrollToID( match2[0].replace( '#', '' ) )
                }
            }

            if( window.CurrentCity ) {
                this.currentCity = window.CurrentCity
            }

            if( window.location.pathname.match(/\/strains\/hybrid/i) ) {
                this.currentStrain = 'hybrid'
            }

            if( window.location.pathname.match(/\/strains\/sativa/i) ) {
                this.currentStrain = 'sativa'
            }

            if( window.location.pathname.match(/\/strains\/indica/i) ) {
                this.currentStrain = 'indica'
            }

        },
    });
};

// Execute async function
main().then((value) => {
    
});
